import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "What resources/ tools do you use?",
  "description": null,
  "author": "Diego",
  "categories": ["faqs"],
  "date": "2020-08-07T00:00:00.000Z",
  "featured": false,
  "tags": [],
  "relatedArticles": ["next-election", "2020-who-are-we-voting-for"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I use is Ballotpedia's `}<a parentName="p" {...{
        "href": "https://ballotpedia.org/Sample_Ballot_Lookup"
      }}>{`sample ballot lookup`}</a>{` tool to help me figure out who's on the ballot.`}</p>
    <p>{`And I like to use `}<a parentName="p" {...{
        "href": "http://branch.vote"
      }}>{`branch.vote`}</a>{`, a local Atlanta company, to learn more about the candidates experience and what they stand for.`}</p>
    <p>{`Extra Credit:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://onyourballot.vote411.org/"
        }}>{`My Choices — VOTE411 Voter Guide`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      